<template>
  <div class="">HEHEHEHEHEHEHEH</div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
// @ is an alias to /src
export default {
  name: "PaymentSuccess",
  components: {},
  data() {
    return {};
  },
  methods: {
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
  },
  created() {
    this.$success({
      title: "This is a success message",
      // JSX support
      content: (
        <div>
          <p>some messages...some messages...</p>
          <p>some messages...some messages...</p>
        </div>
      ),
    });
  },
};
</script>

<style lang="scss" scoped></style>
